import Notify from 'simple-notify'
import 'simple-notify/dist/simple-notify.min.css'

type NotifyStatus = 'success' | 'warning' | 'error'
type NotifyPosition =
  | 'left top'
  | 'top left'
  | 'right top'
  | 'top right'
  | 'left bottom'
  | 'bottom left'
  | 'right bottom'
  | 'bottom right'
  | 'center'
  | 'left y-center'
  | 'right y-center'
  | 'y-center left'
  | 'y-center right'
  | 'top x-center'
  | 'bottom x-center'
  | 'x-center top'
  | 'x-center bottom'

interface NotifyArgs {
  status?: NotifyStatus
  title?: string
  text?: string
  position?: NotifyPosition
}

export function notifySuccess(opts: Omit<NotifyArgs, 'status'>): void {
  notify({ status: 'success', ...opts })
}

export function notifyError(opts: Omit<NotifyArgs, 'status'>): void {
  notify({ status: 'error', ...opts })
}

export function notify(opts: NotifyArgs): void {
  new Notify({
    status: opts.status || 'success',
    title: opts.title,
    text: opts.text,
    effect: 'fade',
    speed: 300,
    showIcon: true,
    showCloseButton: true,
    autoclose: true,
    autotimeout: 7000,
    gap: 20,
    distance: 20,
    type: 1,
    position: opts.position || 'right top',
  })
}
