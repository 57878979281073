import axios, { AxiosRequestConfig } from 'axios'
import { CONFIG } from '@/config'
import { makeApiCallers } from './apiCallers'

const axiosConfig: AxiosRequestConfig = {
  baseURL: CONFIG.API_URL,
  timeout: 60 * 1000, // 1 minute
  headers: {
    'Content-Type': 'application/vnd.api+json',
  },
}

const api = axios.create(axiosConfig)
const callers = makeApiCallers(api)

export { api, callers }
