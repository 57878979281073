import { AxiosStatic, AxiosInstance } from 'axios'
import {
  TokenResponse,
  UserResponse,
  MarketResponse,
  OrderResponse,
  BalanceResponse,
  WalletHistoryItemResponse,
} from './apiTypes'
import { TimestampIso8601 } from '@/types/common'
import {
  attachBearerInjector,
  attachJsonApiUnpacker,
  attachStaleTokenHandler,
} from './apiInterceptors'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function makeApiCallers(axios: AxiosInstance) {
  const api = _cloneAxios(axios)
  attachBearerInjector(api)
  attachJsonApiUnpacker(api)
  attachStaleTokenHandler(api)

  return {
    login(opts: { email: string; password: string }) {
      return api.post<TokenResponse>('api/v1/login', {
        data: {
          type: 'login-model',
          attributes: {
            email: opts.email,
            password: opts.password,
          },
        },
      })
    },

    getMarkets() {
      return api.get<MarketResponse[]>('api/v1/markets')
    },

    getBalances() {
      return api.get<BalanceResponse[]>('api/v1/balance')
    },

    getUsers(params: {
      'page[number]'?: number
      'page[limit]'?: number
      'page[order]'?: number
    }) {
      return api.get<UserResponse[]>('api/v1/accounts', { params })
    },

    deleteUser(id: number) {
      return api.delete(`api/v1/accounts/${id}`)
    },

    getWithdrawals(params: {
      'filter[start_after]'?: TimestampIso8601
      'filter[start_before]'?: TimestampIso8601
      'page[limit]'?: number
    }) {
      return api.get<WalletHistoryItemResponse[]>('api/v1/withdrawals', { params })
    },

    getDeposits(params: {
      'filter[start_after]'?: TimestampIso8601
      'filter[start_before]'?: TimestampIso8601
      'page[limit]'?: number
    }) {
      return api.get<WalletHistoryItemResponse[]>('api/v1/deposits', { params })
    },

    getOrders(params: {
      'filter[market]'?: string
      'filter[start_after]'?: TimestampIso8601
      'filter[start_before]'?: TimestampIso8601
      'page[limit]'?: number
    }) {
      return api.get<OrderResponse[]>('api/v1/orders', { params })
    },

    changePassword(opts: {
      email: string
      oldPassword: string
      newPassword: string
    }) {
      return api.post<TokenResponse>('api/v1/change_password', {
        data: {
          type: 'change-password-model',
          attributes: {
            email: opts.email,
            old_password: opts.oldPassword,
            new_password: opts.newPassword,
          },
        },
      })
    },

    createAccount(opts: {
      email: string
      password: string
      role: string
      ftxSubAccount?: string
    }) {
      return api.post<UserResponse>('api/v1/accounts', {
        data: {
          type: 'account',
          attributes: {
            email: opts.email,
            password: opts.password,
            role: opts.role,
            ftx_sub_account: opts.ftxSubAccount,
          },
        },
      })
    },
  }
}

function _cloneAxios(axios: AxiosInstance) {
  return (axios as AxiosStatic).create()
}
