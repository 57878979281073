/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import axios from 'axios'
import { t } from '@/i18n'
import { notifyError } from '@/helpers/notify'

export function getApiErrorStatus(error: any): number {
  if (axios.isAxiosError(error)) {
    return error.response?.status || -1
  }
  return -1
}

export const reportApiError = (error: any): void => {
  console.error(error)
  notifyError({ text: extractApiError(error) })
}

export const extractApiError = (error: any): string => {
  return error.message || t('api-errors.unexpected')
}
