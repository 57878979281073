// spell-checker:words jsona

import Jsona from 'jsona'
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { getToken } from '@/helpers/token'
import { notifyError } from '@/helpers/notify'
import { getApiErrorStatus } from './apiErrors'
import { t } from '@/i18n'
import store from '@/store'
import router from '@/router'

export function attachBearerInjector(axios: AxiosInstance): void {
  axios.interceptors.request.use((request): AxiosRequestConfig => {
    const token = getToken()
    if (!token) return request

    if (!request.headers) request.headers = {}
    request.headers['Authorization'] = `Bearer ${token.id}`
    return request
  })
}

export function attachJsonApiUnpacker(axios: AxiosInstance): void {
  const jsona = new Jsona()
  axios.interceptors.response.use((success): AxiosResponse => {
    success.data = jsona.deserialize(success.data)
    return success
  })
}

export function attachStaleTokenHandler(axios: AxiosInstance): void {
  axios.interceptors.response.use(undefined, (err) => {
    if (getApiErrorStatus(err) === 401 && getToken() !== null) {
      store.dispatch('LOG_OUT')
      notifyError({
        title: t('api-errors.session-expired-title'),
        text: t('api-errors.session-expired-desc'),
      })
      router.push('Redirector')
    }
    throw err
  })
}
