import { reportApiError } from '@/api'
import { AxiosError, AxiosResponse } from 'axios'
import { ref, Ref } from 'vue'

export function useSubmitter(opts: {
  request: () => Promise<AxiosResponse>
  guard?: () => boolean | Promise<boolean>
  onSuccess?: (response: AxiosResponse) => void | Promise<void>
  onError?: (error: AxiosError | Error) => void | Promise<void>
}): [
  submitWrapped: () => Promise<void>,
  isSubmitting: Ref<boolean>,
  isFailed: Ref<boolean>
] {
  const isSubmitting = ref(false)
  const isFailed = ref(false)

  const submit = async () => {
    const isPrevented = opts.guard ? !(await opts.guard()) : false
    if (isPrevented || isSubmitting.value) return

    isSubmitting.value = true
    isFailed.value = false
    try {
      const response = await opts.request()
      if (opts.onSuccess) {
        await opts.onSuccess(response)
      }
    } catch (error) {
      isFailed.value = true
      if (opts.onError) {
        await opts.onError(error as AxiosError)
      } else {
        reportApiError(error)
      }
    }
    isSubmitting.value = false
  }

  return [submit, isSubmitting, isFailed]
}
